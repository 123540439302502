
import React, { Component } from "react";
import SingleSelect from "../../../components/Form/SingleSelect";
import cmsContent from "../../../MiddleWare/CmsContent";
import LoginModal from "../../../components/Modal/Modal";
import Addauthormodel from "./Addauthormodel";
import { Alert, Input } from "reactstrap";
import { ACCESS_SAMPLE } from "../../../config";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { CSVLink, CSVDownload } from "react-csv";
import AddpagePDF from "../../../components/PDF/Addpagespdf";

//import Theme9 from './addPages/theme9';
import Datatable from "../../../components/Datatable/Datatable";
import { FormMiddleWare } from "../../../components/Form";
import { confirmAlert } from "react-confirm-alert";
import Paginationfun from "../pagination";
import { FilePond } from 'react-filepond';
import 'filepond/dist/filepond.min.css';
import Swal from "sweetalert2"


class AddDealers extends FormMiddleWare {
    constructor(props) {
        super(props);
        this.state = {
            alertVisible: false,
            authorlist: [],
            checked: 0,
            customerId: localStorage.getItem("userId"),
            errorPageName: null,
            data: [],
            MOlist: [],
            Saleslist: [],
            alertVisible1: false,
            btnDisable: false,
            formAlert: false,
            formBtn: false,
            themeSelected: "",
            screen: window.screen.height,
            danger: "btn btn-danger",
            success: "btn btn-success",
            home: "Home",
            set: "SetusHome",
            pageNumber: 1,
            SelectedEnquiries: [],
            bulkAllocate: false,
            adddealer: true,
            excelHead1: [
                { label: "SalesDistrictCode", key: "value" },
                { label: "SalesDistrictName", key: "label" },
            ],
            excelHead2: [
                { label: "MarketingOfficerId", key: "value" },
                { label: "MarketingOfficerName", key: "label" },
            ],
            addUserColumn: [
                { label: "MarketingOfficer", value: 2, type: "selectbox", selectOptions: [] },
                { label: "SalesDistrictCode", value: 2, type: "selectbox", selectOptions: [] },
                { label: "DealerID", value: 4, type: "text" },
                { label: "DealerShopName", value: 5, type: "text" },
                { label: "Email", value: 5, type: "text" },
                { label: "MobileNo", value: 5, type: "number" },
            ],
            multidealerAdd: true,
            statusArray: [],
            userAddRow: "",
            RowCount: "",
            badgeColor: false,
            badgeText: false,
            files: null,
            deletefiles: null,
        };
    }
    column = [
        {
            Header: "DEALER ID",
            accessor: "dealer_code",
        },
        {
            Header: "DEALER SHOP NAME",
            accessor: "dealer_name",
        },
        {
            Header: "EMP ID",
            accessor: "moid",
            Cell: (d) => this.getValueFromArray(d.original.moid, this.state.MOlist),
        },
        {
            Header: "SALES DISTRICT NAME AS PER SAP",
            accessor: "salesid",
            Cell: (d) => this.getValueFromArray(d.original.salesid, this.state.Saleslist, 'sales_code'),
        },
        {
            Header: "EMAIL",
            accessor: "Email",
        },
        {
            Header: "MOBILE",
            accessor: "Mobile",
        },
        {
            Header: "SITE",
            Cell: (d) => this.ViewSite(d),
        },
        {
            Header: "EDIT",
            accessor: "edit",
            Cell: (d) => this.Editpages(d),
        },
        {
            Header: "STATUS",
            // accessor: "edit",
            Cell: (d) => this.buttondelstatus(d),
        },
        // {
        //     Header: "DELETE",
        //     // accessor: "edit",
        //     Cell: (d) => this.buttondel(d),
        // },
        {
            Header: "Delete",
            accessor: "edit",
            Cell: (d) => this.DatatableCheckboxx(d, this.updateMyData, this.state.SelectedEnquiries)
        }


    ];
    buttondelstatus = (d) => {
        if (d.original.status == 0) {
            return (
                <button
                    className="btn btn-success"
                // onClick={() => this.Delete(d)}
                >Active</button>
            );
        } else {
            return (
                <button
                    className="btn btn-danger"

                    onClick={() => this.Recover(d)}
                >InActive</button>
                // <span className="label">Deleted</span>
            )

        }
    }
    Recover = d => {
        confirmAlert({
            customUI: ({ onClose }) => {
                return <div style={{ textAlign: "center" }}>
                    <h1>Recover confirmation</h1>
                    <h4>Are you sure ?</h4><br />
                    <button className="btn btn-primary" onClick={() => {
                        this.RecoverData(d.original)
                        onClose();
                    }}  >Yes</button> &nbsp;  &nbsp;
                    <button className="btn btn-danger" onClick={onClose} >No</button>
                </div>
            }
        })
    };
    RecoverData = async (e) => {
        console.log(e, "ee");
        let { SelectedEnquiries } = this.state

        const RecoverUser = await cmsContent.recoverDealer("cmndl_tbl_dealer", e)
        console.log(RecoverUser, "RecoverUser");
        if (RecoverUser) {
            // this.MainFun()
            setTimeout(() => {
                window.location.reload()
            }, 3000);



        }


    }
    DatatableCheckboxx = (d, func, SelectedEnquiries) => {
        // console.log(d.original.id, "SelectedEnquiries[d.original.id]");
        if (d.original.status == 0) {
            return (
                <div style={{ 'text-align': 'center' }}>
                    <input type="checkbox"
                        checked={SelectedEnquiries && SelectedEnquiries[d.original.id] &&
                            SelectedEnquiries[d.original.id] == true ? true : false}
                        onChange={(event) => func(parseInt(d.original.id), d.column.id, event.target.checked ? "Yes" : "No")} />
                </div>)
        } else {
            return (
                <></>
            )
        }
    };
    updateMyData = async (d, f, event) => {
        let { SelectedEnquiries } = this.state
        console.log(d, "d", event, "event", f, "f");
        console.log(SelectedEnquiries, "SelectedEnquiries");
        let AllocateType = "Delete"
        const index = SelectedEnquiries.indexOf(d)
        console.log(index, "index");
        if (index > -1 && event == "No") {
            SelectedEnquiries.splice(index, 1)
            SelectedEnquiries[d] = false
            if (index == 0) {
                SelectedEnquiries.length = 0;
                this.setState({ bulkAllocate: false })

            }

        }
        else {
            SelectedEnquiries.push(d)
            SelectedEnquiries[d] = true
            this.setState({ bulkAllocate: true })
        }
        this.setState({ SelectedEnquiries, AllocateType })

    }
    selectalldata = async (event) => {
        console.log(event, event);
        let { data } = this.state
        let SelectedEnquiries = []
        console.log(SelectedEnquiries, "SelectedEnquiries");
        console.log(data, "data");

        if (event == "Yes") {
            for (let index = 0; index < 100; index++) {
                const element = data[index];
                if (element.status == 0) {
                    SelectedEnquiries[element.id] = true
                    SelectedEnquiries.push(element.id)
                }
            }

            await this.setState({ isSelectUnallocated: true })
            // console.log(SelectedEnquiries.length, "SelectedEnquiries length");
        }

        else {
            await this.setState({ isSelectUnallocated: false })
        }
        await this.setState({ SelectedEnquiries })
    };
    confirmDeleteT = async (e) => {
        console.log(e, "ee");
        let { SelectedEnquiries } = this.state
        console.log(SelectedEnquiries, "SelectedEnquiries");
        if (SelectedEnquiries) {
            // const filteredData = Object.fromEntries(
            //     Object.entries(SelectedEnquiries).filter(([key, value]) => value === true)
            // );

            const keysWithTrueValues = Object.keys(SelectedEnquiries)
                .filter(key => SelectedEnquiries[key] === true)
                .map(Number);

            console.log(keysWithTrueValues);
            const DeleteMultyUser = await cmsContent.DeleteMultyDealer("cmndl_tbl_dealer", keysWithTrueValues)
            console.log(DeleteMultyUser, "DeleteMultyUser");
            if (DeleteMultyUser) {
                this.setState({ bulkAllocate: false, SelectedEnquiries: [], isSelectUnallocated: false })
                // this.MainFun()

                setTimeout(() => {
                    window.location.reload()
                }, 3000);
            }

        }
    }
    confirmDeleteP = async (e) => {
        console.log(e, "ee");
        let { SelectedEnquiries } = this.state
        console.log(SelectedEnquiries, "SelectedEnquiries");
        this.setState({ loading: true })
        if (SelectedEnquiries) {
            // const filteredData = Object.fromEntries(
            //     Object.entries(SelectedEnquiries).filter(([key, value]) => value === true)
            // );

            const keysWithTrueValues = Object.keys(SelectedEnquiries)
                .filter(key => SelectedEnquiries[key] === true)
                .map(Number);

            console.log(keysWithTrueValues);
            const DeleteMultyUser = await cmsContent.DeletePermanentMultyDealer("cmndl_tbl_dealer", keysWithTrueValues)
            console.log(DeleteMultyUser, "DeleteMultyUser");
            if (DeleteMultyUser) {
                this.setState({ loading: false, bulkAllocate: false, SelectedEnquiries: [], isSelectUnallocated: false })
                // this.MainFun()

                setTimeout(() => {
                    window.location.reload()
                }, 3000);
            }

        }
    }
    multyDelete = d => {
        confirmAlert({
            customUI: ({ onClose }) => {
                return <div style={{ textAlign: "center" }}>
                    {/* <h1>Delete confirmation</h1>
                    <h4>Are you sure you want to Delete this temporary ?</h4><br />
                    <button className="btn btn-primary" onClick={() => {
                        this.confirmDeleteT(d)
                        onClose();
                    }}  >Yes</button> &nbsp;  &nbsp;
                    <button className="btn btn-danger" onClick={onClose} >No</button>
                    <br></br>
                    <br></br> */}

                    <h1>Delete confirmation</h1>
                    <h4>Are you sure you want to Delete this permanetly ?</h4><br />
                    <button className="btn btn-primary" onClick={() => {
                        this.confirmDeleteP(d)
                        onClose();
                    }}  >Yes</button> &nbsp;  &nbsp;
                    <button className="btn btn-danger" onClick={onClose} >No</button>
                </div>
            }
        })
    };






    showVisibility = (id) => {
        return (
            <button
                type="button"
                className="btn btn-info"
                onClick={() => this.updateVisibility(id)}
            >
                Hide/Show
            </button>
        );
    };

    changeLoginStatus = (id) => {
        return (
            <button
                type="button"
                class="btn btn-info btn-lg col-lg-12" style={{ paddingLeft: 5, marginBottom: '4px', fontSize: '10px' }}
                onClick={() => this.updateLogin(id)}
            >
                Change Login Status
            </button>
        );
    };
    buttondel = (d) => {
        if (d.original.status == 0) {
            return (
                <button

                    className="btn btn-danger"
                    onClick={() => this.Delete(d)}
                >Delete</button>
            );
        } else {
            return (<span className="label">Deleted</span>)

        }
    }
    Delete = d => {
        confirmAlert({
            customUI: ({ onClose }) => {
                return <div style={{ textAlign: "center" }}>
                    <h1>Delete confirmation</h1>
                    <h4>Are you sure you want to Delete this ?</h4><br />
                    <button className="btn btn-primary" onClick={() => {
                        this.HandleDelete(d.original)
                        onClose();
                    }}  >Yes</button> &nbsp;  &nbsp;
                    <button className="btn btn-danger" onClick={onClose} >No</button>
                </div>
            }
        })
    };
    HandleDelete = async (d) => {
        try {
            let obj = {}
            obj.status = Date.now()
            const result = await cmsContent.updateMaster(
                "cmndl_tbl_dealer",
                d.id,
                obj
            )
            if (result) {
                this.MainFun();
            }
        } catch (error) {
            console.log(error);
        }
    }


    Editpages = (value) => {
        if (value.original.status == 0) {
            return (
                <button className="btn btn-info" onClick={() => this.onEdit(value)}>
                    Edit
                </button>
            );

        } else {
            return (<span className="label">Deleted</span>)
        }
    };
    ViewSite = (value) => {
        if (value.original.status == 0) {
            return (
                <button className="btn btn-primary"
                    onClick={() => this.handleLogin(value.original.dealer_code)}
                // onClick={() => window.open(`http://localhost:21006/Vidya/Login/${value.original.dealer_code}`)}
                >
                    View site
                </button>
            );

        } else {
            return (<span className="label">Deleted</span>)
        }
    };
    handleLogin = async (value) => {
        try {
            const result = await cmsContent.coromandelHandlelogin(value)
            if (result) {
                // console.log(result);
                if (result.data.status == 'SUCCESS') {
                    window.open(result.data.url + value)
                }
            }
        } catch (err) {
            console.log(err);
        }
    }
    getValueFromArray1 = (d, array, ch = "value", ch1 = "label") => {
        if (array.length > 0) {
            if (array.length !== 0) {
                let filtered = array.filter(function (item) {
                    return item[ch] == d;
                });
                let v = filtered[0];
                if (v != undefined) {
                    return v;
                } else {
                    return '-';
                }
            }
        } else {
            return '-';
        }
    };
    onEdit = (value) => {
        let d = value.original;

        if (d.moid) {
            let MO = this.getValueFromArray1(d.moid, this.state.MOlist)
            // console.log(MO);
            this.setState({ MO })
        }
        if (d.salesid) {
            let Sales = this.getValueFromArray1(d.salesid, this.state.Saleslist, 'sales_code')
            // console.log(Sales);
            this.setState({ Sales })
        }
        this.setState({
            Name: d.dealer_name,
            Code: d.dealer_code,
            Email: d.Email,
            Mobile: d.Mobile,
            editId: d.id,
            isEdit: true
        });
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
        });
    };

    async componentDidMount() {
        this.MainFun()
    }
    MainFun = async () => {
        try {
            this.setState({ loading: true })
            let val = {}
            val.pageNumber = this.state.pageNumber
            const data = await cmsContent.GetCmdlDatas(this.state.customerId, "Dealer", val)
            console.log(data, "data");
            let datas = []
            for (let ival of data.data) {
                if (ival.dealer_code != null && ival.status == "0") {
                    await datas.push(ival)
                }
            }
            await this.setState({
                totalpage: data.totalpage,
                totalrecords: data.totalrecords,
                MOlist: data.MOlist,
                Saleslist: data.Saleslist,
                MOlistcsv: data.MOlistcsv,
                Saleslistcsv: data.Saleslistcsv,
                data: datas,
            })
            this.setState({ loading: false })

        } catch (error) {
            console.log(error);
            this.setState({ loading: false })
        }
    }
    handlechange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    };



    submitCategory = async () => {
        const {
            Code,
            Name, Email, Mobile, errorName, errorCode, errorMO, MO, Sales, isEdit, editId
        } = this.state;
        if (!MO) {
            this.setState({
                errorMO: "select name",
            });
            return false;
        }
        else if (!Sales) {
            this.setState({
                errorSales: "select Sales",
            });
            return false;
        }
        else if (!Code) {
            this.setState({ errorMO: "", errorCode: "enter  code" });
            return false;
        } else if (!Name) {
            this.setState({
                errorName: "enter name",
                errorCode: "",
            });
            return false;
        } else if (!Email) {
            this.setState({
                errorEmail: "enter Email",
                errorName: "",
            });
            return false;
        } else if (!Mobile) {
            this.setState({
                errorMobile: "enter Mobile Number",
                errorEmail: "",
            });
            return false;
        }

        let categoryArray = {}
        categoryArray.moid = MO.value;
        categoryArray.salesid = Sales.sales_code;
        categoryArray.sales_name = Sales.sales_name;
        categoryArray.dealer_code = Code;
        categoryArray.dealer_name = Name;
        categoryArray.Email = Email;
        categoryArray.Mobile = Mobile;
        categoryArray.customerid = this.state.customerId;
        // console.log([...formData]);
        console.log('loast', categoryArray);
        try {
            const result = isEdit ? await cmsContent.updateMaster(
                "cmndl_tbl_dealer",
                editId,
                categoryArray
            ) : await cmsContent.addMaster(
                "cmndl_tbl_dealer",
                categoryArray
            );
            if (result) {
                window.scrollTo({
                    top: 0,
                    behavior: "smooth"
                });
                this.setState({
                    Code: "",
                    Name: '', errorName: '', errorCode: '',
                    MO: '', errorMO: '', errorEmail: '', errorMobile: '',
                    Email: '',
                    Sales: '',
                    Mobile: '',
                    btnDisable: false,
                    alertVisible: true,

                });
                this.MainFun()
                setTimeout(() => this.setState({ alertVisible: false }), 3000);
            }
        } catch (error) {
            console.log(error);
        }
    };

    handleSelect = (name, selectedOption) => {
        this.setState({
            [name]: selectedOption,
            //   themeId: selectedOption.value,
            //   themeName: selectedOption.label,
        });
    };

    handleFileUpload = (fileItems) => {
        this.setState({
            files: fileItems.map((fileItem) => fileItem.file),
        });
    };

    handleDeleteFileUpload = (fileItems) => {
        this.setState({
            deletefiles: fileItems.map((fileItem) => fileItem.file),
        });
    };

    submitcsv = async (e) => {
        await this.setState({ btnDisablecsv: true })
        const { customerId, files } = this.state;
        // console.log(files, "files");
        if (files == null) {
            await this.setState({ btnDisablecsv: false })
            return false
        }

        const formData = new FormData();
        formData.append("file", files[0]);
        formData.append('customerId', customerId);
        // console.log(...formData, "formData");
        // const previousData = [...this.state.data];
        // return false
        try {
            const result = await cmsContent.dealerCsvUpload("cmndl_tbl_dealer", formData);
            // console.log(result, "result");
            let Timing = 3000
            if (result.data) {
                if (result && result.data) {
                    console.log(result);

                    await this.setState({
                        files: "",
                        alertVisible: true,
                        badgeText: result.data.content1 ? result.data.content1 : result.data.content,
                        badgeColor: result.data.color1 ? result.data.color1 : result.data.color,
                        status1: '', status2: '', status3: '', added: result.data.added ? result.data.added : [],
                        notadded: result.data.Notadded ? result.data.Notadded : [],
                        // btnDisablecsv: false,
                    })
                    // return false
                    setTimeout(() => {
                        this.setState({
                            alertVisible: false, textalert: "",
                            badgeText: false, badgeColor: false, btnDisablecsv: false,
                            files: '', notadded: [], added: []
                        }); this.MainFun();
                        window.location.reload()
                    }, 5000);

                }
                // this.setState({
                //     alertVisible: true,
                //     btnDisablecsv: false,
                //     files: ''
                // });
                // setTimeout(() => {
                //     window.location.reload()
                // }, Timing);
            }
        } catch (error) {
            this.setState({
                // data: previousData
            });
            console.log(error);
        }
    }

    submitcsvDealerDelete = async (e) => {
        await this.setState({ btnDisablecsvdelete: true })
        const { customerId, deletefiles } = this.state;
        console.log(deletefiles, "deletefiles");
        if (deletefiles == null) {
            await this.setState({ btnDisablecsvdelete: false })
            return false
        }

        const formData = new FormData();
        formData.append("file", deletefiles[0]);
        formData.append('customerId', customerId);
        try {
            const result = await cmsContent.dealerDeleteCsvUpload("cmndl_tbl_dealer", formData);
            console.log(result, "result");
            let Timing = 3000
            if (result.data) {
                if (result && result.data) {
                    console.log(result);

                    await this.setState({
                        deletefiles: "",files:"",
                        alertVisible: true,
                        badgeText: result.data.content1 ? result.data.content1 : result.data.content,
                        badgeColor: result.data.color1 ? result.data.color1 : result.data.color,
                        status1: '', status2: '', status3: '', added: result.data.added ? result.data.added : [],
                        notadded: result.data.Notadded ? result.data.Notadded : [],
                        btnDisablecsvdelete: false,
                    })
                    // return false
                    setTimeout(() => {
                        this.setState({
                            deletefiles: "",files:"",
                            alertVisible: false, textalert: "",
                            badgeText: false, badgeColor: false, btnDisablecsvdelete: false,
                            deletefiles: '', notadded: [], added: []
                        }); this.MainFun();
                        window.location.reload()
                    }, 5000);

                }
                
            }
        } catch (error) {
            this.setState({
                // data: previousData
            });
            console.log(error);
        }
    }

    handleSelectChange = async (value, rowIndex, columnLabel) => {
        let { adminId } = this.state
        console.log(value, "value");
        console.log(rowIndex, "rowIndex");
        console.log(columnLabel, "columnLabel");

        this.setState(prevState => ({
            statusArray: prevState.statusArray.map((row, index) =>
                index === rowIndex ? { ...row, [columnLabel + "Select"]: value, [columnLabel]: (columnLabel === "DealerID" || columnLabel === "DealerShopName" || columnLabel === "Email" || columnLabel === "MobileNo") ? value.label : value.value } : row
            )
        }));
        // if (columnLabel === "Marketing officer") {
        //   const { data: competencyOptions } = await MasterMiddleWare.selectBoxCompetency(value.label, adminId);

        //   console.log(competencyOptions, "competencyOptions");
        //   this.setState(prevState => ({
        //     statusArray: prevState.statusArray.map((row, index) =>
        //       index === rowIndex ? { ...row, CompetencyOption: competencyOptions } : row
        //     )
        //   }));
        // }
    };

    addRow = (value) => {
        console.log(value, "values");
        let { userAddRow, statusArray, MOlist, Saleslist } = this.state
        // this.setState({ statusArray: [] })
        let addIndex = value ? statusArray.length : 0

        const newItems = Array.from({ length: value ? value : Number(userAddRow) }, (_, index) => ({
            DealerID: "",
            DealerShopName: "",
            Email: "",
            MobileNo: "",
            MarketingOfficerSelect: "",
            SalesDistrictCodeSelect: "",
            MarketingOfficerOption: MOlist,
            SalesDistrictCodeOption: Saleslist,
            index: value ? addIndex : index

        }));
        console.log(newItems, "newItems");
        this.setState(prevState => ({
            statusArray: [...prevState.statusArray, ...newItems]
        }));

        console.log(statusArray, "statusArray");
    }

    handleInputChange = (e, rowIndex, columnLabel) => {
        const { value } = e.target;
        this.setState(prevState => ({
            statusArray: prevState.statusArray.map((row, index) =>
                index === rowIndex ? { ...row, [columnLabel]: value } : row
            )
        }));
    };

    addMultySubmit = async () => {
        let { statusArray, customerId } = this.state

        return new Promise((resolve, reject) => {
            this.setState({ submitDataAlert: true });
            setTimeout(() => {
                this.setState({ submitDataAlert: false });
                resolve();
            }, 3000);
        })
            .then(() => {
                const formData = new FormData();
                formData.append("statusArray", JSON.stringify(statusArray));
                formData.append('customerId', customerId);
                const previousData = [...this.state.data];
                return cmsContent.insertDealerMuldidata("cmndl_tbl_dealer", formData);
            })
            .then(result => {
                if (result.data.Message == "success") {
                    if (result.data.fillDataErrorMsg) {
                        const maxLength = 29;
                        const wrappedErrorMsg = result.data.fillDataErrorMsg.replace(new RegExp(`(.{1,${maxLength}})`, 'g'), "$1<br>");
                        console.log(wrappedErrorMsg, "wrappedErrorMsg");
                        Swal.fire({
                            position: 'center',
                            type: 'warning',
                            title: wrappedErrorMsg,
                            showConfirmButton: false,
                            timer: 6000
                        });

                    } else {

                        Swal.fire({
                            position: 'center',
                            type: 'success',
                            title: 'dealers has been saved',
                            showConfirmButton: false,
                            timer: 1500
                        });
                        // this.getMaster(tableName);
                        this.setState({
                            alertVisible: true,
                            btnDisablecsv: false,
                            statusArray: "",
                            userAddRow: ""
                        });
                        setTimeout(() => {
                            window.location.reload()
                        }, 3000);
                    }

                }
            })
            .catch(error => {
                console.log(error);
                return Promise.reject(error);
            });
    }

    render() {
        const { MOlist, //marketing off
            Saleslist,
            addUserColumn, statusArray, userAddRow, badgeColor, badgeText, notadded, added
        } = this.state;
        // console.log(statusArray, "statusArray");
        return (
            <React.Fragment>
                <main className="main my-4">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="card">
                                    <div className="card-header">
                                        <h3>Add Dealers</h3>
                                    </div>
                                    {this.state.multidealerAdd ?
                                        <div className="card-body">
                                            <div className="row form-group ">
                                                <div className="col-sm-2" />
                                                <div className="col-sm-3" onClick={() => this.setState({ adddealer: true, csvdealer: false, deleteDealer: false })} >
                                                    <label className='multi' style={{ fontSize: '16px' }} htmlFor="dealer">Add single dealer </label>
                                                </div>

                                                <div className="col-sm-3" onClick={() => this.setState({ csvdealer: true, adddealer: false, deleteDealer: false })}>
                                                    <label className='multi' style={{ fontSize: '16px' }} htmlFor="dealer">Add multiple dealer </label>
                                                </div>

                                                <div className="col-sm-3" onClick={() => this.setState({ deleteDealer: true, csvdealer: false, adddealer: false })}>
                                                    <label className='multi' style={{ fontSize: '16px' }} htmlFor="dealer">Delete multiple dealer </label>
                                                </div>

                                                <div className="col-sm-1" />
                                            </div>
                                            {this.state.adddealer ?
                                                <>
                                                    <div className="row form-group">
                                                        <div className="col-sm-2" />
                                                        <div className="col-sm-7">
                                                            <Alert
                                                                className="badge-content"
                                                                color="success"
                                                                isOpen={this.state.alertVisible}
                                                                toggle={this.onDismiss}
                                                            >
                                                                Added Successfully
                                                            </Alert>
                                                        </div>
                                                        <div className="col-sm-3" />
                                                    </div>

                                                    <div className="row form-group">
                                                        <div className="col-sm-2" />
                                                        <div className="col-sm-2">
                                                            <label htmlFor="exampleInputEmail1">Marketing officer</label>
                                                        </div>
                                                        <div className="col-sm-5">
                                                            <SingleSelect
                                                                options={MOlist}
                                                                handleChange={(e) => this.handleSelect('MO', e)}
                                                                selectedService={this.state.MO}
                                                            />
                                                        </div>
                                                        <div className="col-sm-2" >
                                                            {MOlist && MOlist.length ?
                                                                // <div className="row form-group ">
                                                                //     <div className="col-sm-10" />
                                                                //     <div className="col-sm-2">
                                                                <CSVLink
                                                                    headers={this.state.excelHead2}
                                                                    data={this.state.MOlistcsv}
                                                                    filename={"MarketingOfficer.xls"}
                                                                    className="excelexportlink"
                                                                    target="_blank"
                                                                >
                                                                    View Marketing Officer
                                                                </CSVLink>
                                                                //     </div>
                                                                // </div>
                                                                :
                                                                <></>}
                                                        </div>
                                                        <div className="col-sm-1" />
                                                    </div>

                                                    <div className="row form-group">
                                                        <div className="col-sm-4" />
                                                        <span className="error-show ">
                                                            {this.state.errorMO}
                                                        </span>
                                                    </div>
                                                    <div className="row form-group">
                                                        <div className="col-sm-2" />
                                                        <div className="col-sm-2">
                                                            <label htmlFor="exampleInputEmail1">Sales District Code</label>
                                                        </div>
                                                        <div className="col-sm-5">
                                                            <SingleSelect
                                                                options={Saleslist}
                                                                handleChange={(e) => this.handleSelect('Sales', e)}
                                                                selectedService={this.state.Sales}
                                                            />
                                                        </div>
                                                        <div className="col-sm-2" >
                                                            {Saleslist && Saleslist.length ?
                                                                // <div className="row form-group ">
                                                                //     <div className="col-sm-10" />
                                                                //     <div className="col-sm-2">
                                                                <CSVLink
                                                                    headers={this.state.excelHead1}
                                                                    data={this.state.Saleslistcsv}
                                                                    filename={"SalesDistrict.xls"}
                                                                    className="excelexportlink"
                                                                    target="_blank"
                                                                >
                                                                    View Sales District
                                                                </CSVLink>
                                                                //     </div>
                                                                // </div>
                                                                :
                                                                <></>}
                                                        </div>
                                                        <div className="col-sm-1" />
                                                    </div>
                                                    <div className="row form-group">
                                                        <div className="col-sm-4" />
                                                        <span className="error-show ">
                                                            {this.state.errorSales}
                                                        </span>
                                                    </div>

                                                    <div className="row form-group">
                                                        <div className="col-sm-2" />
                                                        <div className="col-sm-2">
                                                            <label htmlFor="exampleInputEmail1">Dealer ID</label>
                                                        </div>
                                                        <div className="col-sm-5">
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                id="pageName"
                                                                name="Code"
                                                                placeholder="Enter code"
                                                                value={this.state.Code}
                                                                onChange={this.handlechange}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="row form-group">
                                                        <div className="col-sm-4" />
                                                        <span className="error-show ">
                                                            {this.state.errorCode}
                                                        </span>
                                                    </div>
                                                    <br />
                                                    <div className="row form-group">
                                                        <div className="col-sm-2" />
                                                        <div className="col-sm-2">
                                                            <label htmlFor="exampleInputEmail1">Dealer shop name</label>
                                                        </div>
                                                        <div className="col-sm-5">
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                id="Name"
                                                                name="Name"
                                                                placeholder="Enter name"
                                                                value={this.state.Name}
                                                                onChange={this.handlechange}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="row form-group">
                                                        <div className="col-sm-4" />
                                                        <span className="error-show ">
                                                            {this.state.errorName}
                                                        </span>
                                                    </div>
                                                    <br />
                                                    <div className="row form-group">
                                                        <div className="col-sm-2" />
                                                        <div className="col-sm-2">
                                                            <label htmlFor="exampleInputEmail1">Email</label>
                                                        </div>
                                                        <div className="col-sm-5">
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                id="Name"
                                                                name="Email"
                                                                placeholder="Enter Email"
                                                                value={this.state.Email}
                                                                onChange={this.handlechange}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="row form-group">
                                                        <div className="col-sm-4" />
                                                        <span className="error-show ">
                                                            {this.state.errorEmail}
                                                        </span>
                                                    </div>
                                                    <br />
                                                    <div className="row form-group">
                                                        <div className="col-sm-2" />
                                                        <div className="col-sm-2">
                                                            <label htmlFor="exampleInputEmail1">Mobile no</label>
                                                        </div>
                                                        <div className="col-sm-5">
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                id="Name"
                                                                name="Mobile"
                                                                placeholder="Enter Mobile"
                                                                value={this.state.Mobile}
                                                                onChange={this.handlechange}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="row form-group">
                                                        <div className="col-sm-4" />
                                                        <span className="error-show ">
                                                            {this.state.errorMobile}
                                                        </span>
                                                    </div>
                                                    <br />
                                                    <div className="row form-group">
                                                        <div className="col-sm-2" />
                                                        <div className="col-sm-2" />
                                                        <div className="col-sm-5">
                                                            <button
                                                                type="button"
                                                                className={`btn ${this.state.isEdit ? 'btn-secondary' : 'btn-primary'}`}
                                                                disabled={this.state.btnDisable}
                                                                onClick={this.submitCategory}
                                                            >
                                                                {this.state.isEdit ? 'Update' : 'Submit'}
                                                            </button>
                                                        </div>
                                                        <div className="col-sm-3" />
                                                    </div>
                                                    <br />
                                                    <div className="row form-group">

                                                        <div className="col-sm-12">
                                                            <p>Total of {this.state.totalrecords ? this.state.totalrecords : '0'} records found , currently showing {this.state.data.length ? this.state.data.length : "0"} records only </p><br />
                                                            {this.state.loading ? <div className="text-center">
                                                                < i className="fa fa-spinner fa-2x fa-spin" style={{ color: "blueviolet" }} ></i>
                                                            </div> : this.state.data ?
                                                                <>
                                                                    {this.state.bulkAllocate == true ?
                                                                        <>
                                                                            <div className="d-flex justify-content-end">
                                                                                <div className="d-flex justify-content-space-between">

                                                                                    <h3 style={{ marginTop: '4px', fontWeight: '600px', fontSize: 'larger' }}>Select all</h3><span>
                                                                                        &nbsp; &nbsp;

                                                                                        <input type="checkbox"
                                                                                            checked={this.state.isSelectUnallocated}
                                                                                            onChange={(event) => this.selectalldata(event.target.checked ? "Yes" : "No")} /></span>
                                                                                </div>
                                                                            </div>
                                                                            <br></br>

                                                                            <div className="d-flex justify-content-end">
                                                                                <button type="button"
                                                                                    class="btn btn-primary waves-effect"
                                                                                    onClick={(d) => this.multyDelete(d)}
                                                                                // onClick={(e) => this.multyDelete(e)}
                                                                                >Delete</button>
                                                                            </div>
                                                                        </>
                                                                        : <></>
                                                                    }
                                                                    <br></br>

                                                                    <Datatable
                                                                        data={this.state.data}
                                                                        columnHeading={this.column}
                                                                    />
                                                                </>
                                                                : null}

                                                        </div>

                                                    </div>
                                                    {(this.state.loading != true) && this.state.totalpage && this.state.totalpage > 1 && this.state.pageNumber && <Paginationfun
                                                        totalpage={this.state.totalpage}
                                                        page={this.state.pageNumber}
                                                        pagechage={async (e) => {
                                                            await this.setState({ pageNumber: e, loading: true, })
                                                            await this.MainFun()
                                                        }} />}
                                                </>
                                                : this.state.csvdealer ?
                                                    <>
                                                        <div className="row form-group">
                                                            <div className="col-sm-2" />
                                                            <div className="col-sm-7">
                                                                <Alert
                                                                    className="badge-content"
                                                                    color={badgeColor && badgeColor}
                                                                    isOpen={this.state.alertVisible}
                                                                    toggle={this.onDismiss}
                                                                >
                                                                    {badgeText && badgeText}
                                                                </Alert>
                                                            </div>
                                                            <div className="col-sm-3" />
                                                        </div>
                                                        {notadded && notadded.length > 0 && <div className="row text-center">
                                                            <div className="col-sm-6">
                                                                <h3>Added</h3>
                                                                <br />
                                                                <h5>EmployeeID</h5>
                                                                {added && added[0] &&
                                                                    added[0].map((ival, i) => {
                                                                        return (
                                                                            <div className="">

                                                                                {ival.DealerID}
                                                                            </div>
                                                                        )
                                                                    })}
                                                            </div>
                                                            <div className="col-sm-6">
                                                                <h3>Not Added</h3>
                                                                <br />
                                                                <h5>EmployeeID</h5>
                                                                {notadded && notadded[0] &&
                                                                    notadded[0].map((ival, i) => {
                                                                        return (
                                                                            <div className="">

                                                                                {ival.DealerID}
                                                                            </div>
                                                                        )
                                                                    })}
                                                            </div>

                                                        </div>
                                                        }
                                                        <div className="row form-group">
                                                            <div className="col-sm-2" />
                                                            <div className="col-sm-2">
                                                                <label htmlFor="exampleInputEmail1">
                                                                    Upload Files:
                                                                </label>
                                                            </div>
                                                            <div className="col-sm-4 filepond">
                                                                <FilePond
                                                                    // type="file"
                                                                    files={this.state.files}
                                                                    // className="custom-file-input"
                                                                    id="customFile"
                                                                    // accept='.csv'
                                                                    acceptedFileTypes={['text/csv', 'application/vnd.ms-excel']}
                                                                    // onChange={this.fileupload}
                                                                    // files={this.state.Testimonial_image}
                                                                    onupdatefiles={this.handleFileUpload}
                                                                    allowReorder={true}
                                                                    allowMultiple={false}
                                                                    width={10}
                                                                    maxFileSize={'300KB'}
                                                                    // imageValidateSizeMaxWidth={550}
                                                                    // imageValidateSizeMaxHeight={355}
                                                                    imagePreviewMinHeight="150"
                                                                    imagePreviewMaxHeight="150"
                                                                    labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
                                                                />

                                                            </div>
                                                            <div className="col-sm-2">
                                                                <a href="../../files/dealerSample.csv" style={{ color: "limegreen", position: "absolute", marginTop: "55px" }} download="dealerSample.csv">
                                                                    download sample.csv
                                                                </a>
                                                            </div>
                                                        </div>

                                                        <br />
                                                        <div className="row form-group">
                                                            <div className="col-sm-4" />
                                                            <div className="col-sm-2">
                                                                <button
                                                                    type="button"
                                                                    className="btn btn-primary"
                                                                    onClick={(e) => this.submitcsv(e)}
                                                                    disabled={this.state.btnDisablecsv ? true : false}
                                                                >
                                                                    {this.state.btnDisablecsv ? <i class="fa fa-circle-o-notch fa-spin"></i> : null}&nbsp;
                                                                    {this.state.btnDisablecsv ? "Please Wait.." : "Submit CSV"}
                                                                </button>
                                                            </div>
                                                            <div className="col-md-2">
                                                                <button className='btn btn-primary' onClick={() => this.setState({ multidealerAdd: false })}>Add multi dealer</button>
                                                            </div>
                                                            <div className="col-sm-4" />
                                                        </div>
                                                    </>
                                                    :
                                                    <>
                                                        <div className="row form-group">
                                                            <div className="col-sm-2" />
                                                            <div className="col-sm-7">
                                                                <Alert
                                                                    className="badge-content"
                                                                    color={badgeColor && badgeColor}
                                                                    isOpen={this.state.alertVisible}
                                                                    toggle={this.onDismiss}
                                                                >
                                                                    {badgeText && badgeText}
                                                                </Alert>
                                                            </div>
                                                            <div className="col-sm-3" />
                                                        </div>
                                                        {notadded && notadded.length > 0 && <div className="row text-center">
                                                            <div className="col-sm-6">
                                                                <h3>Added</h3>
                                                                <br />
                                                                <h5>EmployeeID</h5>
                                                                {added && added[0] &&
                                                                    added[0].map((ival, i) => {
                                                                        return (
                                                                            <div className="">

                                                                                {ival.DealerID}
                                                                            </div>
                                                                        )
                                                                    })}
                                                            </div>
                                                            <div className="col-sm-6">
                                                                <h3>Not Added</h3>
                                                                <br />
                                                                <h5>EmployeeID</h5>
                                                                {notadded && notadded[0] &&
                                                                    notadded[0].map((ival, i) => {
                                                                        return (
                                                                            <div className="">

                                                                                {ival.DealerID}
                                                                            </div>
                                                                        )
                                                                    })}
                                                            </div>

                                                        </div>
                                                        }
                                                        <div className="row form-group">
                                                            <div className="col-sm-2" />
                                                            <div className="col-sm-2">
                                                                <label htmlFor="exampleInputEmail1">
                                                                    Upload Files:
                                                                </label>
                                                            </div>
                                                            <div className="col-sm-4 filepond">
                                                                <FilePond
                                                                    // type="file"
                                                                    deletefiles={this.state.deletefiles}
                                                                    // className="custom-file-input"
                                                                    id="customFile"
                                                                    // accept='.csv'
                                                                    acceptedFileTypes={['text/csv', 'application/vnd.ms-excel']}
                                                                    // onChange={this.fileupload}
                                                                    // files={this.state.Testimonial_image}
                                                                    onupdatefiles={this.handleDeleteFileUpload}
                                                                    allowReorder={true}
                                                                    allowMultiple={false}
                                                                    width={10}
                                                                    maxFileSize={'300KB'}
                                                                    // imageValidateSizeMaxWidth={550}
                                                                    // imageValidateSizeMaxHeight={355}
                                                                    imagePreviewMinHeight="150"
                                                                    imagePreviewMaxHeight="150"
                                                                    labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
                                                                />

                                                            </div>
                                                            <div className="col-sm-2">
                                                                <a href="../../files/dealerDeleteSample.csv" style={{ color: "limegreen", position: "absolute", marginTop: "55px" }} download="dealerDeleteSample.csv">
                                                                    download sample.csv
                                                                </a>
                                                            </div>

                                                        </div>
                                                        <br />
                                                            <div className="row form-group">
                                                                <div className="col-sm-5" />
                                                                <div className="col-sm-2">
                                                                    <button
                                                                        type="button"
                                                                        className="btn btn-primary"
                                                                        onClick={(e) => this.submitcsvDealerDelete(e)}
                                                                        disabled={this.state.btnDisablecsvdelete ? true : false}
                                                                    >
                                                                        {this.state.btnDisablecsvdelete ? <i class="fa fa-circle-o-notch fa-spin"></i> : null}&nbsp;
                                                                        {this.state.btnDisablecsvdelete ? "Please Wait.." : "Submit CSV"}
                                                                    </button>
                                                                </div>
                                                                <div className="col-sm-4" />
                                                            </div>
                                                    </>

                                            }
                                        </div>
                                        :
                                        <>
                                            <div className="row form-group ">
                                                <div className="col-sm-2" >
                                                    <button className='btn btn-primary' onClick={() => this.setState({ multidealerAdd: true })}>Back</button>
                                                </div>
                                            </div>
                                            <br></br>

                                            <>
                                                <div className="row form-group ">
                                                    <div className="col-sm-2" />
                                                    <div className="col-sm-2">
                                                        <label htmlFor="indicators">How many dealers add ?</label>
                                                    </div>
                                                    <div className="col-sm-5">
                                                        <input type="number" className="form-control" name="userAddRow" id="indicators" placeholder="Please enter indicators" value={userAddRow} onChange={this.handleUserInput} />
                                                    </div>
                                                    <div className="col-sm-3" >
                                                        <button className='btn btn-primary' onClick={() => this.addRow(0)} disabled={statusArray && statusArray.length > 0 ? true : false}>Add</button>
                                                        {/* <button className='btn btn-info' onClick={()=>this.setState({statusArray:[]})}>Clear</button> */}
                                                    </div>
                                                    {/* {statusArray && statusArray.length ?
                                          <div className="row form-group ">
                                            <div className="col-sm-10" />
                                            <div className="col-sm-2">
                                              <CSVLink
                                                headers={this.state.excelHead3}
                                                data={statusArray}
                                                filename={"Indicator.xls"}
                                                className="btn btncolor"
                                                target="_blank"
                                              >
                                                Generate Excel
                                              </CSVLink>
                                            </div>
                                          </div>
                                          :
                                          <></>} */}
                                                </div>


                                                <div className="container" style={{ overflowX: "auto", minHeight: "200px" }}>
                                                    <table className="table table-bordered">
                                                        <thead>
                                                            <tr>
                                                                <th>S.No </th>

                                                                {addUserColumn.map((index) => (
                                                                    <th key={index}> {index.label}</th>
                                                                ))}
                                                            </tr>
                                                        </thead>

                                                        <tbody>
                                                            {statusArray && statusArray.length > 0 && statusArray.map((ival, i) => (
                                                                // console.log(ival,"type",i,"i")

                                                                <tr>
                                                                    <td style={{ textAlign: "center" }} >{i + 1}</td>
                                                                    {addUserColumn.map((jval, index) => (
                                                                        <>
                                                                            <td style={{ textAlign: "center" }}  >{jval && jval.type === "textarea" ? <>
                                                                                <textarea
                                                                                    style={{ height: "30px", width: "280px", borderColor: "black" }}
                                                                                    // type="text"
                                                                                    value={ival[jval.label]}
                                                                                    onChange={(e) => this.handleInputChange(e, i, jval.label)}
                                                                                /></> : jval.type === "selectbox" ?
                                                                                <div style={{ width: "200px", backgroundColor: "#e9e7e7" }}>
                                                                                    <SingleSelect
                                                                                        handleChange={(d) => this.handleSelectChange(d, i, jval.label)}
                                                                                        options={ival[jval.label + 'Option']}
                                                                                        selectedService={ival[jval.label + "Select"]}
                                                                                    // disabled={false}
                                                                                    />

                                                                                    {/* {console.log("textypeselected :----", `${ival.textType}`)} */}
                                                                                </div>
                                                                                : jval.type === "number" ?
                                                                                    <input type="number" value={ival[jval.label]}
                                                                                        style={{ width: "200px" }} className="form-control" onChange={(e) => this.handleInputChange(e, i, jval.label)}
                                                                                    ></input>
                                                                                    : jval.type === "text" ?
                                                                                        <input type="text"
                                                                                            style={{ width: "200px" }} value={ival[jval.label]} className="form-control"
                                                                                            onChange={(e) => this.handleInputChange(e, i, jval.label)}
                                                                                        ></input>
                                                                                        :
                                                                                        <>
                                                                                        </>}
                                                                            </td>
                                                                        </>
                                                                    ))}
                                                                </tr>
                                                            ))}

                                                        </tbody>
                                                    </table>
                                                    {this.state.submitDataAlert ?

                                                        <div className="text-center">
                                                            < i className="fa fa-spinner fa-2x fa-spin" style={{ color: "blueviolet" }} ></i>
                                                        </div>
                                                        :
                                                        <>
                                                            {statusArray && statusArray.length > 0 &&

                                                                <div className="row form-group ">
                                                                    <div className="col-sm-1" >
                                                                        <button className='btn btn-primary' onClick={() => this.addRow(1)}>Add one</button>
                                                                    </div>
                                                                    <div className="col-sm-1">
                                                                        <button type="button" className="btn btn-success" onClick={this.addMultySubmit}>
                                                                            Submit
                                                                        </button>
                                                                    </div>
                                                                    <div className="col-sm-1">
                                                                        <CSVLink
                                                                            headers={this.state.excelHead3}
                                                                            data={statusArray}
                                                                            filename={"Indicator.xls"}
                                                                            className="btn btncolor"
                                                                            target="_blank"
                                                                        >
                                                                            Generate Excel
                                                                        </CSVLink>
                                                                    </div>

                                                                </div>
                                                            }
                                                        </>
                                                    }
                                                </div>

                                            </>

                                        </>


                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* {this.state.totalpage && this.state.totalpage > 1 && this.state.pageNumber && <Paginationfun
                        totalpage={this.state.totalpage}
                        page={this.state.pageNumber}
                        pagechage={async (e) => {
                            await this.setState({ pageNumber: e, loading: true, })
                            await this.MainFun()
                        }} />} */}
                </main>
            </React.Fragment>
        );
    }
}

export default AddDealers;
