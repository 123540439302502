
import React, { Component } from "react";
import LoginModal from "../../../components/Modal/Modal";
import { Alert } from "reactstrap";
import Datatable from "../../../components/Datatable/Datatable";
import { FormMiddleWare, SingleSelect } from "../../../components/Form";
import CmsContent from "../../../MiddleWare/CmsContent";
import User from "../../../MiddleWare/User";
import { confirmAlert } from "react-confirm-alert";


class AddSchool extends FormMiddleWare {
    constructor(props) {
        super(props);
        this.state = {
            alertVisible: false,
            isEdit: false,
            customerId: localStorage.getItem("userId"),
            data: [],
            editId: null,
            userid: null,
            badgeColor: false,
            badgeText: false,
            danger: "btn btn-danger",
            success: "btn btn-success",
            updateData: false,
            status: "",
            entityType: "",
            entityOptions: [],
            activeInput: "",
            transcript: "",
        };
        this.recognition = null;
    }
    column = [
        {
            Header: "ENTITY TYPE ",
            accessor: "entityTypeName",
            Cell: (d) => this.upperCase(d),

        },
        {
            Header: "ENTITY NAME",
            accessor: "userName",
        },
        {
            Header: "MOBILE",
            accessor: "mobileNumber",
        },
        {
            Header: "EMAIL",
            accessor: "email",
        },
        {
            Header: "STATUS",
            // accessor: "edit",
            Cell: (d) => this.buttondel(d),
        },
        {
            Header: "EDIT",
            accessor: "edit",
            Cell: (d) => this.Editpages(d, 'customer'),
        },
        {
            Header: "DELETE",
            accessor: "delete",
            Cell: (d) => this.deletePages(d, 'customer'),
        },
        // {
        //     Header: "Delete",
        //     accessor: "edit",
        //     Cell: (d) => this.DatatableCheckboxx(d, this.updateMyData, this.state.SelectedEnquiries)
        // }
    ];
    async componentDidMount() {
        let { salesopt_filtered, transcript } = this.state
        const SpeechRecognition =
            window.SpeechRecognition || window.webkitSpeechRecognition;
        if (SpeechRecognition) {
            this.recognition = new SpeechRecognition();
            this.recognition.continuous = true;
            this.recognition.interimResults = true;
            this.recognition.onresult = (event) => {
                const transcript = Array.from(event.results)
                    .map((result) => result[0].transcript.trim())
                    .join('');
                this.updateActiveInput(transcript);
            };

            this.recognition.onerror = (event) => {
                console.error('Speech recognition error:', event.error);
            };

            this.recognition.onend = () => {
                this.setState({ listening: false });
            };
        } else {
            alert("Your browser doesn't support the Web Speech API.");
        }
        this.MainFun()
        const togglePassword = document.querySelector('#togglePassword');
        const password = document.querySelector('#password');
        document.querySelector('#togglePassword').addEventListener('click', function (e) {
            this.classList.toggle('fa-eye-slash');
            const type = password.getAttribute('type') === 'password' ? 'text' : 'password';
            password.setAttribute('type', type);
        });
    }
    deletePages = (d) => {
        let value = d.original
        return (
            <div>
                <button className="btn btn-danger" onClick={() => this.delete(value)}>Delete</button>
            </div>
        )
    }
    delete = (d) => {
        console.log(d.id)
        confirmAlert({
            customUI: ({ onClose }) => {
                return <div style={{ textAlign: "center" }}>
                    <br></br>
                    <h1>Delete confirmation</h1>
                    <h4>Are you sure you want to Delete this permanetly ?</h4><br />
                    <button className="btn btn-primary" onClick={() => {
                        this.confirmDeleteP(d.id)
                        onClose();
                    }}  >Yes</button> &nbsp;  &nbsp;
                    <button className="btn btn-danger" onClick={onClose} >No</button>
                </div>
            }
        })
    }
    confirmDeleteP = async (d) => {
        console.log(d, "d.id")
        let id = d
        try {
            const result = await User.deleteschool(id)
            if (result) {
                console.log("deleted")
                setTimeout(() => {
                    window.location.reload()
                }, 2000);
            }
        }
        catch (error) {
            console.log(error, "error")
        }
    }
    MainFun = async () => {

        let customerId = localStorage.getItem("userId");
        try {
            const { data } = await User.getAllUser(customerId);
            console.log(data, "data");
            // if (data) {
            //     this.setState({ data });
            // }
            const State = await CmsContent.getFreedom(
                "id as value,entity_name as label",
                "psychol_entity",
                "id",
                "id",
                "id"
            );

            if (State) {
                this.setState({ entityOptions: State.data });
            }
            console.log(State, "StateState");
            console.log(data, "datadata");

            if (data && State.data) {
                State.data.forEach((jval) => {
                    data.map((ival) => {
                        if (jval.value == ival.entitytype) {
                            ival.entityTypeName = jval.label
                        }
                    })
                })
                this.setState({ data: data })
            } else if (data) {

                this.setState({ data: data })
            }


        } catch (error) {
            // console.log(error);
        }
    }
    Editpages = (value, modalWindowId) => {
        if (value.original.status == "active") {
            return (
                <button type="button" data-toggle="modal" data-target={`#${modalWindowId}`} className="btn btn-warning" onClick={() => this.onEdit(value)}>
                    {/* <button className="btn btn-info" onClick={() => this.onEdit(value)}> */}
                    Edit
                </button>
            );

        } else {
            return (<span className="label">Deleted</span>)
        }
    };
    onEdit = async (value) => {

        let d = value.original;
        console.log(d)

        await this.setState({
            entityname: d.userName,
            mobile: d.mobileNumber,
            email: d.email,
            password: d.withoutmd5,
            editId: d.id,
            isEdit: true,
            status: d.status,
            entityType: this.state.entityOptions.filter(ival => ival.value == d.entitytype)
        });
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
        });
    };
    upperCase = (d) => {
        let value = d.original.entityTypeName
        if (value) {
            return value.toUpperCase()
        }
    }
    buttondel = (d) => {
        if (d.original.status == "active") {
            return (
                <button
                    className="btn btn-success"
                    onClick={() => this.Delete(d)}
                >Active</button>
            );
        } else {
            return (
                <button
                    className="btn btn-danger"
                    onClick={() => this.Recover(d)}
                >InActive</button>
                // <span className="label">Deleted</span>
            )

        }
    }
    handlechange = async (e, type, name) => {
        await this.setState({ [e.target.name]: e.target.value, errorSchoolname: "", errorMobile: "", errorEmail: "", errorPassword: "", errorentityType: "", errorEntityname: "" });
    };
    submitCategory = async () => {
        const { entityname, errorEntityname, mobile, errorMobile, email, errorEmail, password, errorPassword, isEdit, editId, status, entityType, errorentityType } = this.state;
        if (!entityType) {
            this.setState({ errorentityType: "Enter select entity type" })
            return false
        }
        if (!entityname) {
            this.setState({ errorEntityname: "Enter entity name" })
            return false
        }
        if (!mobile) {
            this.setState({ errorMobile: "Enter mobile number" })
            return false
        }
        if (mobile.length > 10 || mobile.length < 10) {
            this.setState({ errorMobile: "Entera valid mobile number" })
            return false
        }
        if (!email) {
            this.setState({ errorEmail: "Enter email id" })
            return false
        }
        if (!password) {
            this.setState({ errorPassword: "Enter password " })
            return false
        }
        if (isEdit) {
            let userName = entityname;
            let mobileNumber = mobile;
            let UserPassword = password
            let userId = editId
            const formData = new FormData();
            formData.append("userName", userName);
            formData.append("mobileNumber", mobileNumber);
            formData.append("email", email);
            formData.append("customerId", localStorage.getItem("userId"));
            formData.append("userType", "4");
            formData.append("status", "active");
            formData.append('withoutmd5', UserPassword)
            formData.append("entitytype", this.state.entityType.value)
            try {
                //const result = await User.userInsert(userArray);
                const result = await User.editSelectedUser(userId, formData);
                if (result) {
                    this.setState({
                        alertVisible: true,
                        entityname: "",
                        mobile: "",
                        email: "",
                        password: "",
                        errorSchoolname: "",
                        errorMobile: "",
                        errorEmail: "",
                        errorPassword: "",
                        entityType: "",
                        errorEntityname: "",
                        badgeText: "Successfully Updated ",
                        badgeColor: "success"

                    });
                }
                setTimeout(() => {
                    this.setState({ alertVisible: false })
                    window.location.reload()
                }, 2000);
                this.userList();
            } catch (error) {
                console.log(error);
            }
        }
        else {
            let userName = entityname;
            let mobileNumber = mobile;
            let UserPassword = password

            const formData = new FormData();
            formData.append("userName", userName);
            formData.append("mobileNumber", mobileNumber);
            formData.append("email", email);
            formData.append("customerId", localStorage.getItem("userId"));
            formData.append("userType", "4");
            formData.append("status", "active");
            formData.append('withoutmd5', UserPassword)
            formData.append("entitytype", this.state.entityType.value)
            try {
                //const result = await User.userInsert(userArray);
                const result = await User.adminuserInsert("tbl_user_web", formData);

                if (result) {
                    this.setState({
                        alertVisible: true,
                        entityname: "",
                        mobile: "",
                        email: "",
                        password: "",
                        errorSchoolname: "",
                        errorMobile: "",
                        errorEmail: "",
                        errorPassword: "",
                        entityType: "",
                        errorEntityname: "",
                        badgeText: "Successfully Submitted",
                        badgeColor: "success"

                    });
                }
                setTimeout(() => {
                    this.setState({ alertVisible: false })
                    window.location.reload()
                }, 2000);
                this.userList();
            } catch (error) {
                console.log(error);
            }
        }
    }

    fileupload = e => {
        this.setState({ csv: e.target.files[0] })
    }
    reset = async () => {
        await this.setState({
            errorempname: '', errorCode: '', emp_id: "", emp_name: "", password: '', ansgender: "",
            ansgender: "", ansBusinessUnit: "", ansFunction: "", ansSubFunction: "",
            ansRegion: "", ansDivision: "", ansBranch: "",
            ansoffEmailAdd: "", ansGrade: "", ansBand: "",
            badgeText: false, badgeColor: false, alertVisible: false,
            ansEmpCatType: "", ansL1ManCode: "",
            ansL1Man: "", ansL2ManCode: "", ansL2Man: "", ansHRManCode: "", ansHRMan: "", ansBusiDesignation: "",
            errgender: "", errBusinessUnit: "", errFunction: "", errSubFunction: "", errRegion: "", errDivision: "", errBranch: "", erroffEmailAdd: "", errGrade: "", errBand: "", errEmpCatType: "", errL1ManCode: "",
            errL1Man: "", errL2ManCode: "", errL2Man: "", errHRManCode: "", errHRMan: "", errBusiDesignation: "",
            errorpassword: '', editId: false, userid: false, isEdit: false, alertVisible: false,
        })
    }
    optionSelect = async (selectedOption) => {
        if (selectedOption) {
            // let selectedValue = "";
            // selectedOption.map(values => (selectedValue += `${values.value},`));
            // selectedValue = selectedValue.replace(/,\s*$/, "");
            await this.setState({
                entityType: selectedOption,
            });
        }
    }
    voiceSetup = (value) => {
        console.log(value, "value")
        var para = document.getElementById(`${value}`)
        console.log(para, "para")
        if (para.classList[1] === "bi-mic-fill") {
            para.classList.remove("bi-mic-fill")
            para.classList.add("bi-x-circle")
            console.log("enter here")
            this.handleStart(value)
        }
        else {
            para.classList.add("bi-mic-fill")
            para.classList.remove("bi-x-circle")
            console.log("enter else")
            this.handleStop()
        }
    }
    updateActiveInput = (transcript) => {
        console.log(transcript,"enter here in the transcript")
        const { activeInput } = this.state;
        console.log(activeInput, "activeinput")
        
        if (activeInput) {
            this.setState({
                [activeInput]:transcript
            })
        }
    };
    handleStart = (inputKey) => {
        console.log("enter here in the handlestart")
        const { selectedLanguage } = this.state;
        if (this.recognition) {
            this.recognition.lang = selectedLanguage;
            this.recognition.start();
        }
        this.setState(
            { activeInput: inputKey, listening: true, micON: true },
            () => {
                console.log("Updated state:", this.state);
                this.updateActiveInput("hearing your voice");
            }
        );
    };
    handleStop = () => {
        console.log("enter here in the handlestop")
        if (this.recognition) {
            this.recognition.stop();
        }
        this.setState({ listening: false, activeInput: null, micON: false });
    };
    render() {
        const {
            badgeColor,
            badgeText,
            textalert,
            color,
            MOlist, //marketing off
            Saleslist, alertVisible, added, notadded, loading, isEdit
        } = this.state;

        console.log(this.state.data, "data from render");
        return (
            <React.Fragment>
                <main className="main my-4">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="card">
                                    <div className="card-header">
                                        <h3>Add Entity</h3>
                                    </div>
                                    <div className="card-body">
                                        <div className="inlinebutton">
                                            <LoginModal
                                                buttonTitle="Add Entity"
                                                title={
                                                    this.state.updateData === false
                                                        ? "Add Entity"
                                                        : "Update Entity"
                                                }
                                                id="customer"
                                                onClick={this.reset}
                                                bodyText={
                                                    <div>
                                                        <div className="row form-group">
                                                            <div className="col-sm-1" />
                                                            <div className="col-sm-4">
                                                                <label htmlFor="indicators">ENTITY TYPE</label>
                                                            </div>
                                                            <div className="col-sm-5">
                                                                <SingleSelect
                                                                    handleChange={(d) => this.optionSelect(d)}
                                                                    options={this.state.entityOptions}
                                                                    selectedService={this.state.entityType}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="row form-group">
                                                            <div className="col-sm-1" />
                                                            <div className="col-sm-4" />
                                                            <span className="error-show ">
                                                                {this.state.errorentityType}
                                                            </span>
                                                        </div>
                                                        <br />
                                                        <div className="row form-group">
                                                            <div className="col-sm-1" />
                                                            <div className="col-sm-4">
                                                                <label htmlFor="exampleInputEmail1">ENTITY NAME :</label>
                                                            </div>
                                                            <div className="col-sm-5 mic-input">
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    id="pageName"
                                                                    name="entityname"
                                                                    placeholder="Enter entity name"
                                                                    value={this.state.entityname}
                                                                    onChange={this.handlechange}
                                                                />
                                                                <div className="mic-icon">
                                                                    <p className="bi bi-mic-fill" id="entityname" onClick={() => this.voiceSetup("entityname", "start")}></p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row form-group">
                                                            <div className="col-sm-1" />
                                                            <div className="col-sm-4" />
                                                            <span className="error-show ">
                                                                {this.state.errorEntityname}
                                                            </span>
                                                        </div>
                                                        <br />
                                                        <div className="row form-group">
                                                            <div className="col-sm-1" />
                                                            <div className="col-sm-4">
                                                                <label htmlFor="exampleInputEmail1">MOBILE</label>
                                                            </div>
                                                            <div className="col-sm-5 mic-input">
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    id="Name"
                                                                    name="mobile"
                                                                    maxLength="10"
                                                                    placeholder="Enter Mobile"
                                                                    value={this.state.mobile}
                                                                    onChange={this.handlechange}
                                                                />
                                                                <div className="mic-icon">
                                                                    <p className="bi bi-mic-fill" id="mobile" onClick={() => this.voiceSetup("mobile", "start")}></p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row form-group">
                                                            <div className="col-sm-1" />
                                                            <div className="col-sm-4" />
                                                            <span className="error-show ">
                                                                {this.state.errorMobile}
                                                            </span>
                                                        </div>
                                                        <br />
                                                        <br />
                                                        <div className="row form-group">
                                                            <div className="col-sm-1" />
                                                            <div className="col-sm-4">
                                                                <label htmlFor="exampleInputEmail1">EMAIL</label>
                                                            </div>
                                                            <div className="col-sm-5 mic-input">
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    id="Name"
                                                                    name="email"
                                                                    placeholder="Enter Email id"
                                                                    value={this.state.email}
                                                                    onChange={this.handlechange}
                                                                />
                                                                <div className="mic-icon">
                                                                    <p className="bi bi-mic-fill" id="email" onClick={() => this.voiceSetup("email", "start")}></p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row form-group">
                                                            <div className="col-sm-1" />
                                                            <div className="col-sm-4" />
                                                            <span className="error-show ">
                                                                {this.state.errorEmail}
                                                            </span>
                                                        </div>
                                                        <br />
                                                        <div className="row form-group">
                                                            <div className="col-sm-1" />
                                                            <div className="col-sm-4">
                                                                <label htmlFor="exampleInputEmail1">PASSWORD</label>
                                                            </div>
                                                            <div className="col-sm-3 mic-input">
                                                                <input
                                                                    type="password"
                                                                    className="form-control"
                                                                    id="password"
                                                                    name="password"
                                                                    placeholder="Enter Password"
                                                                    value={this.state.password}
                                                                    onChange={this.handlechange}
                                                                />
                                                            </div>
                                                            <div className="col-sm-3">
                                                                <i class="fa fa-eye" id="togglePassword" style={{ marginLeft: '30px', cursor: 'pointer' }}></i>
                                                            </div>
                                                        </div>
                                                        <div className="row form-group">
                                                            <div className="col-sm-1" />
                                                            <div className="col-sm-4" />
                                                            <span className="error-show ">
                                                                {this.state.errorPassword}
                                                            </span>
                                                        </div>

                                                        <div className="row form-group">
                                                            <div className="col-sm-1" />
                                                            <div className="col-sm-2" />
                                                            <div className="col-sm-7">
                                                                <Alert
                                                                    className="badge-content"
                                                                    color={badgeColor}
                                                                    isOpen={this.state.alertVisible}
                                                                    toggle={this.onDismiss}
                                                                >
                                                                    {badgeText && badgeText}
                                                                </Alert>
                                                            </div>
                                                            <div className="col-sm-3" />
                                                        </div>
                                                        <br />
                                                        <div className="row form-group">
                                                            <div className="col-sm-2" />
                                                            <div className="col-sm-2" />
                                                            <div className="col-sm-5">
                                                                <button
                                                                    type="button"
                                                                    className={`btn ${this.state.isEdit ? 'btn-secondary' : 'btn-primary'}`}
                                                                    disabled={this.state.btnDisable}
                                                                    onClick={this.submitCategory}
                                                                >
                                                                    {this.state.isEdit ? 'Update' : 'Submit'}
                                                                </button>
                                                            </div>
                                                            <div className="col-sm-3" />
                                                        </div>

                                                    </div>
                                                }
                                            />
                                        </div>
                                        <br></br>
                                        <span>
                                            Total of {Object.keys(this.state.data).length} records
                                        </span>
                                        <br></br>

                                        <div className="row form-group">
                                            <div className="col-sm-12">
                                                {loading ? <div className="text-center">
                                                    < i className="fa fa-spinner fa-2x fa-spin" style={{ color: "blueviolet" }} ></i>
                                                </div> : this.state.data ?

                                                    <Datatable
                                                        data={this.state.data}
                                                        columnHeading={this.column}
                                                    />
                                                    : <>No data Found</>}

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </React.Fragment>
        );
    }
}

export default AddSchool;
